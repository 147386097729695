import {LocalCheckoutGateway, registerPaymentMethod} from '@paymentplugins/wc-stripe/checkout';

if (typeof wc_stripe_local_payment_params !== 'undefined') {
    for (const i in wc_stripe_local_payment_params.gateways) {
        const params = wc_stripe_local_payment_params.gateways[i];
        const gateway = new LocalCheckoutGateway(
            params,
            `#wc_stripe_local_payment_${params.gateway_id}`
        );
        registerPaymentMethod(gateway);
    }
}